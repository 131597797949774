import React, { useState, useEffect } from "react";
import sal from "sal.js";

import "./style.scss";
import BannerImg1 from "../../assets/Images/PodModel/hero/1.png";
import BannerImg2 from "../../assets/Images/PodModel/hero/2.svg";
import BannerImg3 from "../../assets/Images/PodModel/hero/3.svg";
import BannerImg4 from "../../assets/Images/PodModel/hero/4.svg";

import Overview from "./overview";
import WeBuildYourTeam from "./we-build-your-team";
import WeHandleYourOperation from "./we-handle-your-operation";
import YouGrowWithoutLimits from "./you-grow-without-limits";
import RevealAnimation from "../RevealAnimation";

const tabItems = [
  {
    title: "Overview",
    id: "",
    count: "",
    heroTitle: "Our Pod Model",
    heroSubtitle: "Redefining Engagement - Introducing the Ryte Way",
    heroImg: BannerImg1,
    mobNav: {
      nextId: "we-build-your-team",
      // prevId: "",
    },
  },
  {
    title: "We build your team",
    id: "we-build-your-team",
    count: "01",
    heroTitle: "We build your team",
    heroSubtitle:
      "Top-tier engineers selected from British’s & India’s  premier talent pool",
    heroImg: BannerImg2,
    mobNav: {
      nextId: "we-handle-your-operation",
      prevId: "",
    },
  },
  {
    title: "We handle your operation",
    id: "we-handle-your-operation",
    count: "02",
    heroTitle: "We handle your operation",
    heroSubtitle:
      "Effortless operations management allows you to prioritise high-quality deliveries",
    heroImg: BannerImg3,
    mobNav: {
      nextId: "you-grow-without-limits",
      prevId: "we-build-your-team",
    },
  },
  {
    title: "You grow without limits",
    id: "you-grow-without-limits",
    count: "03",
    heroTitle: "You grow without limits",
    heroSubtitle:
      "Ongoing assistance to facilitate your expansion as your business evolves",
    heroImg: BannerImg4,
    mobNav: {
      nextId: "",
      prevId: "we-build-your-team",
    },
  },
];

function HeroTabSection() {
  const [activeTab, setActiveTab] = useState(() => {
    const hash = window.location.hash.replace("#", "");
    return tabItems.find((tab) => tab.id === hash) ? hash : "";
  });

  useEffect(() => {
    window.location.hash = activeTab;
  }, [activeTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "we-build-your-team":
        return <WeBuildYourTeam />;
      case "we-handle-your-operation":
        return <WeHandleYourOperation />;
      case "you-grow-without-limits":
        return <YouGrowWithoutLimits />;
      default:
        return <Overview btnClick={setActiveTab} />;
    }
  };

  const activeTabData = tabItems.find((tab) => tab.id === activeTab);
  const prevTabData = tabItems.find(
    (tab) => tab?.id === activeTabData?.mobNav?.prevId
  );

  const nextTabData = tabItems.find(
    (tab) => tab?.id === activeTabData?.mobNav?.nextId
  );

  return (
    <div id={activeTab}>
      <div className="common-o-bg hero-banner-wrap">
        <div className="body-container hero-banner-container">
          <div className="hero-banner-inner-section">
            <div className="banner-content">
              <RevealAnimation component={"h2"} className="title-header">
                {activeTabData.heroTitle}
              </RevealAnimation>
              <RevealAnimation component={"p"} className="sub-title">
                {activeTabData.heroSubtitle}
              </RevealAnimation>
            </div>
            <RevealAnimation component={"div"} className="banner-img-wrap">
              <img src={activeTabData.heroImg} alt="banner" />
            </RevealAnimation>
          </div>
        </div>
      </div>
      <div className="hero-tab-section">
        <div className="common-o-bg">
          <div className="body-container hero-tab-list">
            {tabItems.map((item) => (
              <div
                key={item.id}
                className={`hero-tab-item ${
                  activeTab === item.id ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(item.id);
                  sal();
                }}
              >
                {item.count && <p>{item.count}</p>}
                <p>{item.title}</p>
              </div>
            ))}
          </div>
          <div className="tab-nav-list">
            {prevTabData && (
              <div
                className="tab-nav-prev"
                onClick={() => setActiveTab(prevTabData.id)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C10.1215 0 12.1568 0.843067 13.657 2.34299C15.157 3.84299 16 5.87835 16 8C16 10.1216 15.1569 12.1568 13.657 13.657C12.157 15.157 10.1216 16 8 16C5.87835 16 3.84315 15.1569 2.34299 13.657C0.842989 12.157 0 10.1216 0 8C0.00230789 5.87914 0.845391 3.84532 2.34535 2.34535C3.84536 0.845351 5.87914 0.00236308 8 0ZM8 14.7692C9.79539 14.7692 11.5168 14.0562 12.787 12.787C14.0562 11.517 14.7692 9.79554 14.7692 8C14.7692 6.20446 14.0562 4.48315 12.787 3.213C11.517 1.94377 9.79554 1.23077 8 1.23077C6.20446 1.23077 4.48315 1.94385 3.213 3.213C1.94377 4.48299 1.23077 6.20446 1.23077 8C1.23308 9.79462 1.94692 11.5155 3.21615 12.7839C4.48461 14.0531 6.20544 14.7669 8 14.7692ZM5.53846 7.56539C5.29845 7.8054 5.29845 8.19462 5.53846 8.43463L8.82078 11.7169C9.06079 11.957 9.45 11.957 9.69001 11.7169C9.93002 11.4769 9.93002 11.0877 9.69001 10.8469L6.81848 8.00002L9.64078 5.1531C9.7954 4.91465 9.76694 4.60233 9.57232 4.3954C9.37771 4.18924 9.06694 4.1431 8.82078 4.28309L5.53846 7.56539Z"
                    fill="white"
                  />
                </svg>

                <p>{prevTabData.title}</p>
              </div>
            )}
            {nextTabData && (
              <div
                className="tab-nav-next"
                onClick={() => setActiveTab(nextTabData.id)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C5.87855 0 3.84315 0.843067 2.34299 2.34299C0.842988 3.84299 0 5.87835 0 8C0 10.1216 0.843067 12.1568 2.34299 13.657C3.84299 15.157 5.87835 16 8 16C10.1216 16 12.1568 15.1569 13.657 13.657C15.157 12.157 16 10.1216 16 8C15.9977 5.87914 15.1546 3.84532 13.6546 2.34535C12.1546 0.845351 10.1209 0.00236308 8 0ZM8 14.7692C6.20461 14.7692 4.48315 14.0562 3.213 12.787C1.94377 11.517 1.23077 9.79554 1.23077 8C1.23077 6.20446 1.94385 4.48315 3.213 3.213C4.48299 1.94377 6.20446 1.23077 8 1.23077C9.79554 1.23077 11.5168 1.94385 12.787 3.213C14.0562 4.48299 14.7692 6.20446 14.7692 8C14.7669 9.79462 14.0531 11.5155 12.7839 12.7839C11.5154 14.0531 9.79456 14.7669 8 14.7692ZM10.4615 7.56539C10.7015 7.8054 10.7015 8.19462 10.4615 8.43463L7.17922 11.7169C6.93921 11.957 6.55 11.957 6.30999 11.7169C6.06998 11.4769 6.06998 11.0877 6.30999 10.8469L9.18152 8.00002L6.35922 5.1531C6.2046 4.91465 6.23306 4.60233 6.42768 4.3954C6.62229 4.18924 6.93306 4.1431 7.17922 4.28309L10.4615 7.56539Z"
                    fill="white"
                  />
                </svg>

                <p>{nextTabData.title}</p>
              </div>
            )}
          </div>
        </div>
        <div className="hero-tab-child">{renderTabContent()}</div>
      </div>
    </div>
  );
}

export default HeroTabSection;
