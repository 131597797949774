import React from "react";
import TitleListImgSection from "../TitleListImgSection";
import BannerImg1 from "../../assets/Images/PodModel/we-build-team/unlock-global-expertise.png";
import BannerImg2 from "../../assets/Images/PodModel/we-build-team//unlock-top-talent-in-india.png";
import BannerImg3 from "../../assets/Images/PodModel/we-build-team/building-cultural-harmony.png";
import BannerImg4 from "../../assets/Images/PodModel/we-build-team/smooth-integration.png";

import IconImg1 from "../../assets/Images/PodModel/icons/quality.png";
import IconImg2 from "../../assets/Images/PodModel/icons/doc-check.png";
import IconImg3 from "../../assets/Images/PodModel/icons/chat.png";
import IconImg4 from "../../assets/Images/PodModel/icons/genuine.png";
import IconImg5 from "../../assets/Images/PodModel/icons/target.png";
import IconImg6 from "../../assets/Images/PodModel/icons/success.png";

import HiringProcessGraph from "../../assets/Images/PodModel/process/graph.svg";
import HiringProcessContent from "../../assets/Images/PodModel/process/content.svg";
import HiringProcessMob from "../../assets/Images/PodModel/process/mob.svg";

import CtaCardSection from "../CTASection/ctaCard2";
import CTAImg from "../../assets/Images/PodModel/cta/coding.png";
import OurServicesCard from "../OurServicesCard";
import CardWithIcon from "../CardWithIcon";
import RevealAnimation from "../RevealAnimation";

function WeBuildYourTeam() {
  const ListStep1 = {
    title: (
      <>
        Unlock Global <br />
        Expertise
      </>
    ),
    items: [
      {
        title: "Overcome Talent Shortage:",
        description:
          "Struggling to find skilled developers locally? We provide a solution by sourcing top-tier talent from India & UK.",
      },
      {
        title: "Seamless Integration:",
        description:
          "Our handpicked teams seamlessly integrate into your existing development setup, acting as dedicated full-time colleagues aligned with your business goals.",
      },
    ],
  };
  const ListStep2 = {
    title: (
      <>
        Unlock Top Talent in <br />
        India & UK
      </>
    ),
    items: [
      {
        title: "Access Global Tech Hub:",
        description:
          "Leverage the expertise of top software developers from Bangalore, Chennai, Hyderabad, London, Manchester, and Edinburgh. These cities are renowned as global tech hubs, offering a blend of cutting-edge innovation and world-class talent.",
      },
      {
        title: "Tap into Unmatched Skills:",
        description:
          "With 1.5 million developers graduating in India every year and the UK’s strong focus on tech education, you can access skills and expertise that are often challenging to find locally. Both regions are home to leading tech giants like Microsoft, Amazon, and Google, ensuring a deep pool of highly skilled professionals ready to drive your projects forward.",
      },
    ],
  };
  const ListStep3 = {
    title: (
      <>
        Building Cultural <br />
        Harmony for <br />
        Seamless Integration
      </>
    ),
    items: [
      {
        title: "Our diverse team:",
        description:
          "At Digiryte, we bridge cultural gaps by seamlessly integrating British and Indian work ethics, values, and communication styles, creating a truly unified team for your business.",
      },
      {
        title: "Tailored Alignment:",
        description:
          "We carefully align your team from India and  UK with your company’s ethos, embedding your unique DNA into their workflow and mindset to foster exceptional collaboration and productivity.",
      },
    ],
  };
  const ListStep4 = {
    title: (
      <>
        Smooth Integration, <br />
        Streamlined <br />
        Collaboration
      </>
    ),
    items: [
      {
        title: "Effortless Team Integration:",
        description:
          "Your team from India and the UK seamlessly integrates into your existing workflow, adopting the same tools and communication channels as your in-house team. With you in full control of their daily responsibilities, collaboration becomes simple and efficient.",
      },
      {
        title: "Strong Communication Skills:",
        description:
          "All team members, whether in India or the UK, are proficient in English, ensuring clear and effective communication at every step. This shared understanding fosters smoother collaboration, smarter working practices, and enhanced productivity.",
      },
    ],
  };
  const IconList = [
    {
      iconUrl: IconImg1,
      title: "Your team is Handpicked from the top 3% of global talent",
    },
    {
      iconUrl: IconImg2,
      title: "You directly control team’s workflow",
    },
    {
      iconUrl: IconImg3,
      title: "Seamless communication and direct collaboration with your team",
    },
    {
      iconUrl: IconImg4,
      title: "Your developers are Genuine colleagues, not outsourced suppliers",
    },
    {
      iconUrl: IconImg5,
      title: "Team is aligned with your business goals and culture",
    },
    {
      iconUrl: IconImg6,
      title: "Your team will be dedicated to delivering exceptional results",
    },
  ];
  return (
    <div>
      <TitleListImgSection
        subTitle={ListStep1.title}
        listItems={ListStep1.items}
        btnLink=""
        imageUrl={BannerImg1}
        wrapClassName="mt-70"
        stepCount=""
      />
      <div className=" mt-70 common-bg-light">
        <TitleListImgSection
          subTitle={ListStep2.title}
          listItems={ListStep2.items}
          btnLink="/about-us/"
          imageUrl={BannerImg2}
          wrapClassName="row-reverse"
          stepCount=""
        />
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              To Strengthen your team <br />
              with world-class coding experts <br />{" "}
              <span className="animationTitle">Contact Us</span>
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          // className="common-o-bg"
        />
      </div>
      <div className="common-bg-light mt-70">
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Our </span>
            Hiring Process
          </RevealAnimation>
        </div>

        <div className="mt-50 ">
          <div className="body-container show-only-web">
            <RevealAnimation
              animation="zoom-in"
              className="hiring-process-graph"
              style={{ width: "100%" }}
            >
              <img
                src={HiringProcessGraph}
                alt=""
                style={{ width: "inherit" }}
              />
            </RevealAnimation>
            <RevealAnimation
              animation="zoom-in"
              className="hiring-process-content mt-30"
              style={{ width: "100%" }}
            >
              <img
                src={HiringProcessContent}
                alt=""
                style={{ width: "inherit" }}
              />
            </RevealAnimation>
          </div>
          <div className="body-container show-only-mob">
            <div className="hiring-process-graph" style={{ width: "100%" }}>
              <img src={HiringProcessMob} alt="" style={{ width: "inherit" }} />
            </div>
          </div>
        </div>
      </div>
      <TitleListImgSection
        subTitle={ListStep3.title}
        listItems={ListStep3.items}
        btnLink=""
        imageUrl={BannerImg3}
        wrapClassName="mt-70"
        stepCount=""
      />
      <div className=" mt-70 common-bg-light">
        <TitleListImgSection
          subTitle={ListStep4.title}
          listItems={ListStep4.items}
          btnLink="/about-us/"
          imageUrl={BannerImg4}
          wrapClassName="row-reverse"
          stepCount=""
        />
      </div>
      <div className=" mt-70">
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Real </span>
            Value for IT Leaders
          </RevealAnimation>
        </div>

        <div className="mt-50 body-container">
          <CardWithIcon data={IconList} />
        </div>
      </div>
      <div className="common-bg-light mt-70">
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Our </span>
            Engagement Models
          </RevealAnimation>
        </div>

        <div className="mt-70 mb-20">
          <OurServicesCard />
        </div>
      </div>
    </div>
  );
}

export default WeBuildYourTeam;
