import React from "react";
import OurServicesCard from "../OurServicesCard";
import TitleListImgSection from "../TitleListImgSection";

import Get_Ready_to_Code from "../../assets/Images/PodModel/you-grow/Get_Ready_to_Code.png";
import Unlock_Your_Team from "../../assets/Images/PodModel/you-grow/Unlock_Your_Team.png";
import Access_Unparalleled_Talent from "../../assets/Images/PodModel/you-grow/Access_Unparalleled_Talent.png";
import Adapt_with_Ease from "../../assets/Images/PodModel/you-grow/Adapt_with_Ease.png";
import Lasting_Partnership from "../../assets/Images/PodModel/you-grow/Lasting_Partnership.png";
import Outpace_Your_Competitors from "../../assets/Images/PodModel/you-grow/Outpace_Your_Competitors.png";
import IconImg1 from "../../assets/Images/PodModel/icons/Global_Locations.svg";
import IconImg2 from "../../assets/Images/PodModel/icons/Teams_Built.svg";
import IconImg3 from "../../assets/Images/PodModel/icons/Successful_Partnerships.svg";

import ContactCta from "../../assets/Images/PodModel/cta/contact-cta.png";

import CtaCardSection from "../CTASection/ctaCard2";
import RevealAnimation from "../RevealAnimation";
import SliderSection from "../Slider";

import HeroImage1Slide from "../../assets/Images/PodModel/you-grow/slide/1.png";
import HeroImage2Slide from "../../assets/Images/PodModel/you-grow/slide/2.png";
import HeroImage3Slide from "../../assets/Images/PodModel/you-grow/slide/3.png";
import HeroImage4Slide from "../../assets/Images/PodModel/you-grow/slide/4.png";

function YouGrowWithoutLimits() {
  const ListStep1 = {
    title: "Collaborate with Your Development Team",
    subTitle: <>Get Ready to Code!</>,
    description:
      "With your developers on board and operations seamlessly managed, your team is primed to dive into new projects and swiftly deliver services. We stand by you every step of the way, facilitating rapid development and innovation. Our approach empowers limitless scaling, propelling your organisation towards unprecedented levels of success.",
  };
  const ListStep2 = {
    title: (
      <>
        Unlock Your Team’s <br />
        Potential
      </>
    ),
    description:
      "Expand your tech teams effortlessly with our turnkey R&D centers in India and the UK. Whether you need a small team of 4 or a large team of 100+, our proven model offers a ready-to-go solution for scaling your development efforts. Experience rapid growth as our partners often double or triple their team sizes within the first two years—no more tedious recruitment processes, just a seamless, scalable approach to success.",
  };
  const ListStep3 = {
    title: (
      <>
        Access Unparalleled <br />
        Talent
      </>
    ),
    description:
      "Tap into a vast pool of exceptional talent through our recruitment process, carefully designed to select the top 3% of the brightest engineers from India and the UK. With R&D centers in India's rapidly growing tech hubs and the UK's dynamic tech ecosystem, we attract top-tier talent from global tech giants, ensuring you have access to the best of the best in engineering expertise.",
  };

  const ListStep4 = {
    title: <>Adapt with Ease</>,
    description:
      "Stay ahead of the curve by easily adding any technology to your toolkit whenever needed. Whether it’s Ruby, SAP, or mobile development, rely on Digiryte to quickly mobilize our recruitment process and provide the specialized expertise you need.",
  };
  const ListStep5 = {
    title: (
      <>
        Forge a Lasting <br />
        Partnership
      </>
    ),
    description:
      "Build more than just teams – cultivate enduring strategic alliances with Digiryte. With our robust infrastructure, industry expertise, and proven track record, rest assured that we’ll guide you through every step of your journey. Leave the groundwork, logistics, and operations in India & UK to us while you focus on driving innovation and growth.",
  };
  const ListStep6 = {
    title: (
      <>
        Outpace Your <br />
        Competitors
      </>
    ),
    description:
      "Experience an accelerated trajectory towards your goals with Digiryte by your side. With us as your trusted partner, you’ll not only recruit top-tier engineering talent but also streamline logistics, manage operations, and chart a course towards unparalleled innovation and boundless growth",
  };
  const IconList = [
    {
      imgUrl: IconImg1,
      title: (
        <>
          Global Locations. <br />
          Establishing our presence worldwide
        </>
      ),
    },
    {
      imgUrl: IconImg2,
      title: (
        <>
          Teams Built Since 2016.
          <br />
          Proven expertise in assembling high-performing teams
        </>
      ),
    },
    {
      imgUrl: IconImg3,
      title: (
        <>
          Years of Successful Partnerships.
          <br />
          Building lasting relationships since our inception
        </>
      ),
    },
  ];

  const listObjectSlideData = [
    {
      title: "The ultimate evidence?",
      imageUrl: HeroImage1Slide,
      description:
        "Our clients now refrain from labeling their teams as ’offshore’ because…",
    },
    {
      title: "The ultimate evidence?",
      imageUrl: HeroImage2Slide,
      description:
        "Our clients now refrain from labeling their teams as ’offshore’ because…",
    },
    {
      title: "The ultimate evidence?",
      imageUrl: HeroImage3Slide,
      description:
        "Our clients now refrain from labeling their teams as ’offshore’ because…",
    },
    {
      title: "The ultimate evidence?",
      imageUrl: HeroImage4Slide,
      description:
        "Our clients now refrain from labeling their teams as ’offshore’ because…",
    },
  ];

  return (
    <div>
      <TitleListImgSection
        title={ListStep1.title}
        subTitle={ListStep1.subTitle}
        description={ListStep1.description}
        listItems={[]}
        btnLink=""
        imageUrl={Get_Ready_to_Code}
        wrapClassName="mt-70"
        stepCount=""
      />
      <div className="body-container mt-70">
        <div className="list-thumb-icon-wrap">
          {IconList.map((item, index) => (
            <RevealAnimation key={index} className="list-thumb-icon">
              <div className="list-thumb-img">
                <img src={item.imgUrl} />
              </div>
              <div className="list-thumb-title">{item.title}</div>
            </RevealAnimation>
          ))}
        </div>
      </div>

      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              We’re Your Partner in <br />
              <span className="animationTitle">Limitless</span> Growth
            </>
          }
          // titleClassName="customTitle"
          imageUrl={ContactCta}
          imgClassName="custom-cta-img custom-cta-darken"
        />
      </div>
      <div className=" mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"p"}
            className="ai-subtitle"
            style={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            RYTE SOLUTIONS, RYTE RESULTS
          </RevealAnimation>
          <RevealAnimation
            component={"h2"}
            className="title-header"
            style={{ textAlign: "center", width: "100%" }}
          >
            Global Excellence Tailored to You
          </RevealAnimation>
        </div>
        <TitleListImgSection
          subTitle={ListStep2.title}
          description={ListStep2.description}
          listItems={[]}
          btnLink=""
          imageUrl={Unlock_Your_Team}
          wrapClassName="mt-50 row-reverse"
          stepCount=""
        />
      </div>
      <div className="common-bg-light mt-70">
        <TitleListImgSection
          subTitle={ListStep3.title}
          description={ListStep3.description}
          listItems={[]}
          btnLink=""
          imageUrl={Access_Unparalleled_Talent}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <TitleListImgSection
        subTitle={ListStep4.title}
        description={ListStep4.description}
        listItems={[]}
        btnLink=""
        imageUrl={Adapt_with_Ease}
        wrapClassName="mt-70 row-reverse"
        stepCount=""
      />
      <div className="common-bg-light mt-70">
        <TitleListImgSection
          subTitle={ListStep5.title}
          description={ListStep5.description}
          listItems={[]}
          btnLink=""
          imageUrl={Lasting_Partnership}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <TitleListImgSection
        subTitle={ListStep6.title}
        description={ListStep6.description}
        listItems={[]}
        btnLink=""
        imageUrl={Outpace_Your_Competitors}
        wrapClassName="mt-70 row-reverse"
        stepCount=""
      />
      <SliderSection
        listObjectData={listObjectSlideData}
        wrapClassName="common-o-bg mt-70"
        hideController={true}
      />
      <div className="mt-50">
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Our </span>
            Engagement Models
          </RevealAnimation>
        </div>

        <div className="mt-70 mb-20">
          <OurServicesCard />
        </div>
      </div>
    </div>
  );
}

export default YouGrowWithoutLimits;
