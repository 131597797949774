import React from "react";
import OurServicesCard from "../OurServicesCard";
import TitleListImgSection from "../TitleListImgSection";

import BannerImg1 from "../../assets/Images/PodModel/we-handle-operation/banner-1.png";
import BannerImg2 from "../../assets/Images/PodModel/we-handle-operation/banner-2.png";
import EXPERT_COORDINATION_IMG from "../../assets/Images/PodModel/we-handle-operation/EXPERT_COORDINATION.png";
import Accessible_Team_Insights_Img from "../../assets/Images/PodModel/we-handle-operation/Accessible_Team_Insights.png";
import EFFICIENT_RECRUITMENT_PROCESS_IMG from "../../assets/Images/PodModel/we-handle-operation/EFFICIENT_RECRUITMENT_PROCESS.png";
import Transparent_Reporting_Img from "../../assets/Images/PodModel/we-handle-operation/Transparent_Reporting.png";
import COMPREHENSIVE_HR_SUPPORT_IMG from "../../assets/Images/PodModel/we-handle-operation/COMPREHENSIVE_HR_SUPPORT.png";

import IconImg1 from "../../assets/Images/PodModel/icons/workspace.png";
import IconImg2 from "../../assets/Images/PodModel/icons/manage.png";
import IconImg3 from "../../assets/Images/PodModel/icons/dashboard.png";
import IconImg4 from "../../assets/Images/PodModel/icons/idea.png";
import CardWithIcon from "../CardWithIcon";
import RevealAnimation from "../RevealAnimation";
import SliderSection from "../Slider";

import HeroImage1Slide from "../../assets/Images/PodModel/we-handle-operation/slide/Equipment.png";
import HeroImage2Slide from "../../assets/Images/PodModel/we-handle-operation/slide/Logistics.png";
import HeroImage3Slide from "../../assets/Images/PodModel/we-handle-operation/slide/ITSecurity.png";

function WeHandleYourOperation() {
  const ListStep1 = {
    title: "MANAGED OPERATIONS SIMPLIFIED",
    subTitle: (
      <>
        Let us handle the <br />
        heavy lifting for you
      </>
    ),
    description:
      "With our managed operations model, you can concentrate on driving your business forward while we handle the operational complexities. You retain control over key aspects such as work schedules and project planning, while we take care of the heavy lifting, allowing you to focus on growth and innovation for your clientele.",
  };

  const ListStep2 = {
    title: (
      <>
        Modernise Your <br />
        Workspace
      </>
    ),
    description:
      "Your team will thrive in a modern, fully-equipped office space within our business center, customised to reflect your brand and equipped with essential amenities such as:",
    items: [
      {
        title: "",
        description:
          "100 Mbps leased line backed by a 1Gbps commercial fiber failover",
      },
      {
        title: "",
        description: "Conference facilities for meetings and video conferences",
      },
      {
        title: "",
        description: "Comfortable lounge areas with refreshments",
      },
      {
        title: "",
        description: "Provision of office supplies including notepads and pens",
      },
    ],
  };

  const ListStep3 = {
    title: "EXPERT COORDINATION",
    subTitle: <>Guiding Your Success</>,
    description:
      "Your dedicated Account Manager based in UK is your trusted ally, available round the clock to optimise team efficiency and address any operational challenges.",
  };

  const ListStep4 = {
    title: "COMPREHENSIVE HR SUPPORT",
    subTitle: (
      <>
        Tailored Administrative <br />
        Assistance
      </>
    ),
    description:
      "Our HR and Admin experts provide ongoing support to ensure smooth day-to-day operations, including assistance with onboarding, payroll management, and fostering a positive work environment.",
  };

  const ListStep5 = {
    title: "EFFICIENT RECRUITMENT PROCESS",
    subTitle: <>Scalability Simplified</>,
    description:
      "Our recruitment team works tirelessly to facilitate seamless team expansion, allowing you to scale your development capabilities as needed.",
  };

  const ListStep6 = {
    title: (
      <>
        Accessible Team <br />
        Insights
      </>
    ),
    description:
      "Our intuitive online platform provides comprehensive visibility into various aspects of your operations, including team structure, recruitment progress, candidate profiles, and expenditure.",
    listHeadline: "Key highlights include:",
    items: [
      {
        title: "",
        description: "A breakdown of team structure",
      },
      {
        title: "",
        description: "Insights into the recruitment pipeline",
      },
      {
        title: "",
        description: "Profiles of potential candidates",
      },
      {
        title: "",
        description: "Expense tracking",
      },
    ],
  };

  const ListStep7 = {
    title: <>Transparent Reporting</>,
    description:
      "Our periodic reports provide a clear snapshot of team progress, recent enhancements, and upcoming goals, ensuring you and your board stay in the loop.",
    listHeadline: "Highlights include:",
    items: [
      {
        title: "",
        description: "Monthly summaries",
      },
      {
        title: "",
        description: "Annual Recap",
      },
    ],
  };

  const IconList = [
    {
      iconUrl: IconImg1,
      title: "We Oversee Your Workspace and Equipment",
    },
    {
      iconUrl: IconImg2,
      title: "We Manage Your Administrative, Logistical, and HR Needs",
    },
    {
      iconUrl: IconImg3,
      title: "Enjoy On-Demand Access to Robust Data-Driven Analytics",
    },
    {
      iconUrl: IconImg4,
      title: "Ensure Your Engineers Align with Your Company’s Vision",
    },
  ];

  const listObjectSlideData = [
    {
      title: "Equipment",
      imageUrl: HeroImage1Slide,
      description:
        "We ensure your team has the necessary equipment and infrastructure, including spacious workstations, ergonomic seating, and either MacBook Pro or ThinkPad setups with dual monitors.",
    },
    {
      title: "Logistics",
      imageUrl: HeroImage2Slide,
      description:
        "We handle logistical aspects such as setting up your presence in India & UK, managing local taxes, payroll, and country-specific technicalities are taken care of on your behalf.",
    },
    {
      title: "IT Security",
      imageUrl: HeroImage3Slide,
      description:
        "We prioritise security with meticulous attention, customizing measures to fit your needs and implementing precautions like NDAs and 24/7 guards on-site. Our ISO 27001 certification underscores our dedication to safeguarding your data.",
    },
  ];

  return (
    <div>
      <TitleListImgSection
        title={ListStep1.title}
        subTitle={ListStep1.subTitle}
        description={ListStep1.description}
        listItems={[]}
        btnLink=""
        imageUrl={BannerImg1}
        wrapClassName="mt-70"
        stepCount=""
      />
      <div className=" mt-70 common-bg-light">
        <div className="body-container">
          <RevealAnimation
            component={"p"}
            className="ai-subtitle"
            style={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            SEAMLESS ADMINISTRATION
          </RevealAnimation>
          <RevealAnimation
            component={"h2"}
            className="title-header"
            style={{ textAlign: "center", width: "100%" }}
          >
            Your Trusted Operational Partner
          </RevealAnimation>
        </div>
        <TitleListImgSection
          subTitle={ListStep2.title}
          subDescription={ListStep2.description}
          listItems={ListStep2.items}
          btnLink=""
          imageUrl={BannerImg2}
          wrapClassName="mt-50 row-reverse"
          stepCount=""
        />
      </div>
      <SliderSection listObjectData={listObjectSlideData} wrapClassName="" />
      <div className="common-bg-light">
        <TitleListImgSection
          title={ListStep3.title}
          subTitle={ListStep3.subTitle}
          description={ListStep3.description}
          listItems={[]}
          btnLink=""
          imageUrl={EXPERT_COORDINATION_IMG}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <TitleListImgSection
        title={ListStep4.title}
        subTitle={ListStep4.subTitle}
        description={ListStep4.description}
        listItems={[]}
        btnLink=""
        imageUrl={COMPREHENSIVE_HR_SUPPORT_IMG}
        wrapClassName="mt-70 row-reverse"
        stepCount=""
      />
      <div className=" mt-70 common-bg-light">
        <TitleListImgSection
          title={ListStep5.title}
          subTitle={ListStep5.subTitle}
          description={ListStep5.description}
          listItems={[]}
          btnLink=""
          imageUrl={EFFICIENT_RECRUITMENT_PROCESS_IMG}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <div className=" mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"p"}
            className="ai-subtitle"
            style={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            DATA-DRIVEN REPORTING
          </RevealAnimation>
          <RevealAnimation
            component={"h2"}
            className="title-header"
            style={{ textAlign: "center", width: "100%" }}
          >
            Insights at Your Fingertips
          </RevealAnimation>
        </div>
        <TitleListImgSection
          subTitle={ListStep6.title}
          subDescription={ListStep6.description}
          listItems={ListStep6.items}
          listHeadline={ListStep6.listHeadline}
          btnLink=""
          imageUrl={Accessible_Team_Insights_Img}
          wrapClassName="mt-50 row-reverse"
          stepCount=""
        />
      </div>
      <div className=" mt-70 common-bg-light">
        <TitleListImgSection
          subTitle={ListStep7.title}
          subDescription={ListStep7.description}
          listHeadline={ListStep7.listHeadline}
          listItems={ListStep7.items}
          btnLink=""
          imageUrl={Transparent_Reporting_Img}
          wrapClassName=""
          stepCount=""
        />
      </div>

      <div className=" mt-70">
        <div className="body-container ttc">
          <RevealAnimation
            component={"p"}
            style={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            WE STAND BY YOU
          </RevealAnimation>
          <RevealAnimation
            component={"h2"}
            className="title-header"
            style={{ textAlign: "center", width: "100%" }}
          >
            Harness Fully Managed Operations
          </RevealAnimation>
        </div>

        <div className="mt-50 body-container">
          <CardWithIcon data={IconList} wrapClassName="column-4" />
        </div>
      </div>

      <div className=" mt-70 common-bg-light">
        <div className="body-container ttc">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Our </span>
            Engagement Models
          </RevealAnimation>
        </div>

        <div className="mt-70 mb-20">
          <OurServicesCard />
        </div>
      </div>
    </div>
  );
}

export default WeHandleYourOperation;
